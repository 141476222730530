@font-face {
  font-family: 'cryptomc-icons';
  src:  url('fonts/cryptomc-icons.eot?opf5qo');
  src:  url('fonts/cryptomc-icons.eot?opf5qo#iefix') format('embedded-opentype'),
    url('fonts/cryptomc-icons.ttf?opf5qo') format('truetype'),
    url('fonts/cryptomc-icons.woff?opf5qo') format('woff'),
    url('fonts/cryptomc-icons.svg?opf5qo#cryptomc-icons') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'cryptomc-icons' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-account:before {
  content: "\e900";
}
.icon-add-in-box:before {
  content: "\e901";
}
.icon-add:before {
  content: "\e902";
}
.icon-arrow-left:before {
  content: "\e903";
}
.icon-arrow-right:before {
  content: "\e904";
}
.icon-ban:before {
  content: "\e905";
}
.icon-bank:before {
  content: "\e906";
}
.icon-bar:before {
  content: "\e907";
}
.icon-batch:before {
  content: "\e908";
}
.icon-bitcoin:before {
  content: "\e909";
}
.icon-book:before {
  content: "\e90a";
}
.icon-briefcase:before {
  content: "\e90b";
}
.icon-budget:before {
  content: "\e90c";
}
.icon-calculator:before {
  content: "\e90d";
}
.icon-calendar:before {
  content: "\e90e";
}
.icon-camera:before {
  content: "\e90f";
}
.icon-cancel:before {
  content: "\e910";
}
.icon-cash-transit:before {
  content: "\e911";
}
.icon-cash:before {
  content: "\e912";
}
.icon-chat:before {
  content: "\e913";
}
.icon-check:before {
  content: "\e914";
}
.icon-chevron-bottom:before {
  content: "\e915";
}
.icon-chevron-left:before {
  content: "\e916";
}
.icon-chevron-right:before {
  content: "\e917";
}
.icon-chevron-top:before {
  content: "\e918";
}
.icon-comment:before {
  content: "\e919";
}
.icon-confirmation:before {
  content: "\e91a";
}
.icon-content-managment:before {
  content: "\e91b";
}
.icon-copy:before {
  content: "\e91c";
}
.icon-credit-card:before {
  content: "\e91d";
}
.icon-crypto-settings:before {
  content: "\e91e";
}
.icon-date-confirm:before {
  content: "\e91f";
}
.icon-document:before {
  content: "\e920";
}
.icon-dollar-bill:before {
  content: "\e921";
}
.icon-dollar:before {
  content: "\e922";
}
.icon-download-cloud:before {
  content: "\e923";
}
.icon-download:before {
  content: "\e924";
}
.icon-edit:before {
  content: "\e925";
}
.icon-eye-slash:before {
  content: "\e926";
}
.icon-eye:before {
  content: "\e927";
}
.icon-files:before {
  content: "\e928";
}
.icon-filter:before {
  content: "\e929";
}
.icon-folder:before {
  content: "\e92a";
}
.icon-global:before {
  content: "\e92b";
}
.icon-help:before {
  content: "\e92c";
}
.icon-history:before {
  content: "\e92d";
}
.icon-home:before {
  content: "\e92e";
}
.icon-idea:before {
  content: "\e92f";
}
.icon-information:before {
  content: "\e930";
}
.icon-link:before {
  content: "\e931";
}
.icon-location:before {
  content: "\e932";
}
.icon-locations:before {
  content: "\e933";
}
.icon-lock:before {
  content: "\e934";
}
.icon-mail:before {
  content: "\e935";
}
.icon-market-place:before {
  content: "\e936";
}
.icon-marketing:before {
  content: "\e937";
}
.icon-money--receive:before {
  content: "\e938";
}
.icon-money-send:before {
  content: "\e939";
}
.icon-more:before {
  content: "\e93a";
}
.icon-newspaper:before {
  content: "\e93b";
}
.icon-note:before {
  content: "\e93c";
}
.icon-notification:before {
  content: "\e93d";
}
.icon-paper-clip:before {
  content: "\e93e";
}
.icon-pen:before {
  content: "\e93f";
}
.icon-phone:before {
  content: "\e940";
}
.icon-piggy-bank:before {
  content: "\e941";
}
.icon-price-tag:before {
  content: "\e942";
}
.icon-qr-code:before {
  content: "\e943";
}
.icon-refresh:before {
  content: "\e944";
}
.icon-reverse:before {
  content: "\e945";
}
.icon-rocket:before {
  content: "\e946";
}
.icon-save:before {
  content: "\e947";
}
.icon-search:before {
  content: "\e948";
}
.icon-security:before {
  content: "\e949";
}
.icon-settings:before {
  content: "\e94a";
}
.icon-shopping-bag:before {
  content: "\e94b";
}
.icon-shopping-cart:before {
  content: "\e94c";
}
.icon-shuffle:before {
  content: "\e94d";
}
.icon-sign-out:before {
  content: "\e94e";
}
.icon-statistics:before {
  content: "\e94f";
}
.icon-trash:before {
  content: "\e950";
}
.icon-trend:before {
  content: "\e951";
}
.icon-unlock:before {
  content: "\e952";
}
.icon-user-add:before {
  content: "\e953";
}
.icon-user-remove:before {
  content: "\e954";
}
.icon-user:before {
  content: "\e955";
}
.icon-users:before {
  content: "\e956";
}
.icon-warning:before {
  content: "\e957";
}
.icon-zoom-in:before {
  content: "\e958";
}
.icon-zoom-out:before {
  content: "\e959";
}
