[class^="payment-method-"], [class*="payment-method-"] {
    background-position: center center;
    background-size: contain;
    background-repeat: no-repeat;
}



























